export const environment = {
    production: false,
    // apiURL: 'http://192.168.0.27:5000/',
    apiURL: 'https://api-dev.crizac.com/',
    fileBaseUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    callBaseUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/yeasterdownload/',
    zegoCloud: 'https://connect.crizac.com/',
    apiPHPURL: 'https://accounts.crizac.com/',
    apiPHPURLv2: 'https://accounts.crizac.com/api_mongo/',
    fileDownloadUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com',
    oneSignalAppId: '65c6e8f2-5801-46f8-a049-633176bb17e0',
    chatBaseUrl: 'wss://api-dev.crizac.com/support-chat-notification',
    transportBaseURL: "https://api-dev.crizac.com/",
    agentPortallink: 'https://agent-dev-new.crizac.com/',
    predictorApiKey: '1ad11872-0537-4406-b01e-de5c52995492',
    predictorApiUrl: 'https://eligibility-predictor.crizac.com',
    fileProfileUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/users/profiles/',
    sopURL:'https://sop-generator.crizac.com/'
};
