import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localStorage/localStorage.service';

@Injectable({
    providedIn: 'root',
})
export class HttpClientService {
    baseURL: string = environment.apiURL;
    phpBaseURL: string = environment.apiPHPURL;
    phpBaseUrlv2: string = environment.apiPHPURLv2;

    constructor(
        private httpClient: HttpClient,
        private localStorageService: LocalStorageService
    ) {}

    private getHeaderAuth() {
        const token = this.localStorageService.getKey('access_token');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }),
        };
        return httpOptions;
    }

    private getHeaderAuthForFormData() {
        const token = this.localStorageService.getKey('access_token');
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + token,
            }),
        };
        return httpOptions;
    }

    private getHeaderBasicAuth() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + btoa('akorn:akorn'),
            }),
        };
        return httpOptions;
    }

    private getHeaderBasicAuthForFormData() {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Basic ' + btoa('akorn:akorn'),
            }),
        };
        return httpOptions;
    }

    private getHeaderBasicAuthForFormDataWithProgress() {
        const token = this.localStorageService.getKey('access_token');
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + token,
            }),
            reportProgress: true,
            observe: 'events',
        };
        return httpOptions;
    }

    private getPasswordResetAuth(token: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }),
        };
        return httpOptions;
    }

    public getResetPasswordAuth(url: string, payload: any) {
        let httpOptions = this.getPasswordResetAuth(payload.token);
        delete payload.token;
        return this.httpClient.post<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public get(url: string, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.get<unknown>(
            `${this.baseURL}${url}`,
            httpOptions
        );
    }

    public getWithqueryParams(
        url: string,
        isBasicAuth: boolean = false,
        queryparm
    ) {
        let httpOptions: any = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        httpOptions.params = queryparm;
        return this.httpClient.get<unknown>(
            `${this.baseURL}${url}`,
            httpOptions
        );
    }

    public post(url: string, payload: unknown, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.post<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public download(
        url: string,
        payload: unknown,
        isBasicAuth: boolean = false
    ) {
        let httpOptions: any = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        httpOptions = {
            ...httpOptions,
            responseType: 'blob',
        };
        return this.httpClient.post<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public put(url: string, payload: unknown, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.put<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public delete(url: string, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.delete<unknown>(
            `${this.baseURL}${url}`,
            httpOptions
        );
    }

    public upload(url: string, payload: unknown, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuthForFormData();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuthForFormData();
        }
        return this.httpClient.post<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public uploadWithProgress(
        url: string,
        payload: unknown,
        isBasicAuth: boolean = false
    ) {
        let httpOptions = this.getHeaderBasicAuthForFormData();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderBasicAuthForFormDataWithProgress();
        }
        return this.httpClient.post<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public csv(url: string) {
        return this.httpClient.get(`${this.baseURL}${url}`, {
            responseType: 'blob',
        });
    }

    public zip(url: string) {
        let httpOptions: any = this.getZipHeaderAuth();
        return this.httpClient.get(`${this.baseURL}${url}`, httpOptions);
    }

    public eml(url: string, payload: any) {
        let httpOptions: any = this.getZipHeaderAuth();
        return this.httpClient.post(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    private getZipHeaderAuth() {
        const token = this.localStorageService.getKey('access_token');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }),
            responseType: 'blob',
        };
        return httpOptions;
    }

    public getPhp(url: string, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.get<unknown>(
            `${this.phpBaseURL}${url}`,
            httpOptions
        );
    }

    public getPhpV2(url: string, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.get<unknown>(
            `${this.phpBaseUrlv2}${url}`,
            httpOptions
        );
    }
}
